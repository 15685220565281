import "./NavToggleButton.scss";

function NavToggleButton(props) {
  return (
    <button id="menu-icon" onClick={props.toggleNav}>
      <i className="las la-bars"></i>
    </button>
  );
}

export default NavToggleButton;
