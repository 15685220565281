import { useEffect, useState } from "react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL;

function useSplashData() {
  const [state, setState] = useState({
    loadStatus: "LOADING",
    reviews: null,
    slideImages: null,
  });

  useEffect(() => {
    Promise.all([
      axios.get(API_BASE_URL + "/api/reviews"),
      axios.get(API_BASE_URL + "/api/slideshow"),
    ]).then((all) => {
      const [reviews, slides] = all;
      setState((prev) => {
        return {
          ...prev,
          reviews: [...reviews.data],
          slideImages: [...slides.data],
        };
      });
    });
  }, []);

  return {
    state,
  };
}

export default useSplashData;
