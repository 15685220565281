import "./SplashGrid.scss";
import GoogleMap from "../minicomponents/GoogleMap";
import SpeechBubble from "../minicomponents/SpeechBubble";
import ImageBackgroundDiv from "../minicomponents/ImageBackgroundDiv";
import Slideshow from "../minicomponents/Slideshow";
import usePageData from "../../hooks/usePageData";

function SplashGrid(props) {
  const { state } = usePageData("about_us");

  return (
    <div id="splash-grid">
      <ImageBackgroundDiv
        additionalClasses="main-image extra-wide-3-1"
        imageURL="https://res.cloudinary.com/beauty-and-beyond-abbotsford/image/upload/v1620100460/beauty%20and%20beyond/banners/blankblacksplash_dmcwwc.jpg"
      >
        <div className="splash-text">
          <h2>MON-SAT</h2>
          <p className="large">10:30 AM to 6:30 PM</p>
          <h2>SUNDAYS</h2>
          <p className="large">10:30 AM to 5:00 PM</p>
          <hr />
          <h2>BOOK NOW</h2>
          <p>(604) 756-1244</p>
        </div>
      </ImageBackgroundDiv>

      <Slideshow slideImages={props.slides} />

      <div className="tall-1-2">
        <GoogleMap />
      </div>

      <div
        className="extra-wide-3-1"
        dangerouslySetInnerHTML={{ __html: state.content || "Loading" }}
        id="about-us-section"
      ></div>

      <ImageBackgroundDiv
        additionalClasses="small-1-1"
        imageURL="https://res.cloudinary.com/beauty-and-beyond-abbotsford/image/upload/v1618260787/beauty%20and%20beyond/utility/aabxvhtjv750hwfse789.png"
        backgroundBlendMode="lighten"
      >
        <SpeechBubble>
          <p>
            {(props.reviews === null && "Loading...") ||
              props.reviews[0].content}
          </p>
          <hr />
          <div className="review-author">
            {(props.reviews === null && "Loading...") ||
              props.reviews[0].author}
          </div>
        </SpeechBubble>
      </ImageBackgroundDiv>
      <ImageBackgroundDiv
        additionalClasses="small-1-1"
        imageURL="https://res.cloudinary.com/beauty-and-beyond-abbotsford/image/upload/v1618260851/beauty%20and%20beyond/utility/qnbtb9clp52ck9jhmic6.png"
        backgroundBlendMode="lighten"
      >
        <SpeechBubble>
          <p>
            {(props.reviews === null && "Loading...") ||
              props.reviews[1].content}
          </p>
          <hr />
          <div className="review-author">
            {(props.reviews === null && "Loading...") ||
              props.reviews[1].author}
          </div>
        </SpeechBubble>
      </ImageBackgroundDiv>
      <ImageBackgroundDiv
        additionalClasses="small-1-1"
        imageURL="https://res.cloudinary.com/beauty-and-beyond-abbotsford/image/upload/v1618260953/beauty%20and%20beyond/utility/vcv3fsc0p1cyfivsdpw2.png"
        backgroundBlendMode="lighten"
      >
        <SpeechBubble>
          <p>
            {(props.reviews === null && "Loading...") ||
              props.reviews[2].content}
          </p>
          <hr />
          <div className="review-author">
            {(props.reviews === null && "Loading...") ||
              props.reviews[2].author}
          </div>
        </SpeechBubble>
      </ImageBackgroundDiv>
    </div>
  );
}

export default SplashGrid;
