import axios from "axios";
import { useEffect, useState } from "react";

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL;

function useGalleryData() {
  const [galleryImages, setGalleryImages] = useState();

  useEffect(() => {
    axios.get(API_BASE_URL + "/api/gallery").then((results) => {
      setGalleryImages(results.data);
    });
  }, []);

  return {
    galleryImages,
  };
}

export default useGalleryData;
