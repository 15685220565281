function GoogleMap() {
  return (
    <>
      <iframe
        id="google-map"
        title="google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2614.09484025741!2d-122.38175558414102!3d49.06583189403169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485cad60df09355%3A0x3e9240819b0454cc!2sBeauty%20%26%20Beyond%20Hair%20Salon!5e0!3m2!1sen!2sca!4v1592880120575!5m2!1sen!2sca"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </>
  );
}

export default GoogleMap;
