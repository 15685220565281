import "./Splash.scss";
import SplashGrid from "./minicomponents/SplashGrid";
import useSplashData from "../hooks/useSplashData";
import NavToggleButton from "./minicomponents/NavToggleButton";

function Splash(props) {
  const { state } = useSplashData();

  return (
    <>
      <header>
        <div className="header-with-navicon">
          <NavToggleButton toggleNav={props.toggleNav} />
          <h1>Beauty and Beyond</h1>
        </div>
        <SplashGrid reviews={state.reviews} slides={state.slideImages} />
      </header>
    </>
  );
}

export default Splash;
