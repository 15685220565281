import useServicesData from "../hooks/useServicesData";
import "./ServicesRenderer.scss";
import { useParams, Link } from "react-router-dom";

function ServicesRenderer(props) {
  const providerID = Number(useParams().providerID) || 1;
  const { services, providers } = useServicesData(providerID);
  // console.log(providerID);
  // console.log(services);
  // console.log(providers);

  const convertCentsToDollars = (cents) => {
    const dollars = cents / 100;
    return dollars.toFixed(2);
  };

  const renderProviders = (providers) => {
    if (!providers) {
      return "None";
    }
    return providers.map((provider) => {
      return (
        <Link
          className="provider-button"
          key={provider.id}
          to={"/services/" + provider.id}
        >
          {provider.display_name}
        </Link>
      );
    });
  };

  const renderServices = (services) => {
    if (!services || services === "None") {
      return (
        <div className="category-block">
          <h2>No Results</h2>
          <p>
            Sorry, there are no services associated with the employee ID you
            provided.
          </p>
        </div>
      );
    }

    return Object.entries(services).map(([category, services]) => {
      return (
        <div key={category} className="category-block">
          <h2>{category}</h2>
          <div>
            {services.map((service) => {
              return (
                <div key={service.id}>
                  <div>
                    <h3>{service.name}</h3>
                    <p>{service.description}</p>
                  </div>
                  <div>
                    <h4>{convertCentsToDollars(service.price)}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div>{renderProviders(providers)}</div>
      <div>{renderServices(services)}</div>
    </>
  );
}

export default ServicesRenderer;
