import { useState } from "react";

function useNavToggle() {
  const [navToggle, setNavToggle] = useState(false);

  const toggleNav = function () {
    setNavToggle((prev) => {
      if (prev) {
        return false;
      }
      return true;
    });
  };

  return {
    navToggle,
    toggleNav,
  };
}

export default useNavToggle;
