import "./Footer.scss";

function Footer() {
  return (
    <footer>
      <div>Beauty and Beyond</div>
      <div>&copy; Sen Tang, 2021</div>
    </footer>
  );
}

export default Footer;
