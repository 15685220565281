import { useEffect, useState } from "react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL;

function usePageData(pageRef) {
  const [state, setState] = useState({
    loaded: false,
  });

  useEffect(() => {
    axios.get(API_BASE_URL + "/api/page/" + pageRef).then((results) => {
      setState((prev) => {
        return {
          loaded: true,
          ...results.data,
        };
      });
    });
  }, [pageRef]);

  return {
    state,
  };
}

export default usePageData;
