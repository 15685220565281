import axios from "axios";
import { useEffect, useState } from "react";

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL;

function useAppData() {
  const [state, setState] = useState({
    address: null,
    phoneNumber: null,
    facebookLink: null,
    instagramLink: null,
    googleLink: null,
  });

  useEffect(() => {
    axios.get(API_BASE_URL + "/api/app_data").then((results) => {
      setState((prev) => {
        return {
          ...prev,
          ...results.data,
        };
      });
    });
  }, []);

  return {
    state,
  };
}

export default useAppData;
