import NavToggleButton from "./minicomponents/NavToggleButton";
import usePageData from "../hooks/usePageData";
import ImageBackgroundDiv from "./minicomponents/ImageBackgroundDiv";

function ManagedPage(props) {
  const { state } = usePageData(props.pageRef);

  return (
    <>
      <header>
        <div className="header-with-navicon">
          <NavToggleButton toggleNav={props.toggleNav} />
          <h1>{state.loaded ? state.pageName : "Loading"}</h1>
          {state.headerImageUrl ? (
            <ImageBackgroundDiv
              imageURL={state.headerImageUrl}
              minHeight={300}
            />
          ) : (
            ""
          )}
        </div>
      </header>
      <main>
        <div
          dangerouslySetInnerHTML={{ __html: state.content || "Loading" }}
        ></div>
        {props.children}
      </main>
    </>
  );
}

export default ManagedPage;
