import "./Slideshow.scss";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import IndividualSlide from "../minicomponents/IndividualSlide";

function Slideshow(props) {
  const renderSlides = function (slideImages) {
    if (!slideImages || slideImages.length === 0) {
      return (
        <IndividualSlide
          imageText={"No images uploaded. Please upload some in the DAM!"}
        />
      );
    } else {
      return slideImages.map((slide) => {
        return (
          <IndividualSlide
            image={slide.url}
            imageText={slide.description}
            backgroundSize={slide.coverMode}
          />
        );
      });
    }
  };

  return (
    <div className="slide-container big-2-2">
      <Slide>{renderSlides(props.slideImages)}</Slide>
    </div>
  );
}

export default Slideshow;
