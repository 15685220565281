import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import useGallerydata from "../../hooks/useGalleryData";

function MasonryGallery(props) {
  const { galleryImages } = useGallerydata();
  console.log(galleryImages);

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
      <Masonry gutter={20}>
        {galleryImages
          ? galleryImages.map((image, i) => (
              <img
                key={i}
                src={image}
                style={{ width: "100%", display: "block" }}
                alt=""
              />
            ))
          : "Loading"}
      </Masonry>
    </ResponsiveMasonry>
  );
}

export default MasonryGallery;
