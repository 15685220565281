// import "./SpeechBubble.scss";

function ImageBackgroundDiv(props) {
  return (
    <div
      className={props.additionalClasses || ""}
      style={{
        background: `url(${props.imageURL})` || "white",
        backgroundPosition: props.backgroundPosition || "center",
        backgroundSize: props.backgroundSize || "cover",
        backgroundRepeat: props.backgroundRepeat || "no-repeat",
        backgroundBlendMode: props.backgroundBlendMode || "normal",
        minHeight: props.minHeight || 0,
      }}
    >
      {props.children}
    </div>
  );
}

export default ImageBackgroundDiv;
