import "./SpeechBubble.scss";

function SpeechBubble(props) {
  return (
    <div
      className="speech-bubble"
      style={{
        background: props.backgroundColor || "white",
      }}
    >
      {props.children}
    </div>
  );
}

export default SpeechBubble;
