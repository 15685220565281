import axios from "axios";
import { useEffect, useState } from "react";

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL;

function useServicesData(providerID) {
  const [services, setServices] = useState();
  const [providers, setProviders] = useState();

  useEffect(() => {
    console.log("getting services data");
    Promise.all([
      axios.get(API_BASE_URL + "/api/services?provider=" + providerID),
      axios.get(API_BASE_URL + "/api/providers"),
    ])
      .then((results) => {
        const [retrievedServices, retrievedProviders] = results;
        setServices(retrievedServices.data);
        setProviders(retrievedProviders.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [providerID]);

  return {
    services,
    providers,
  };
}

export default useServicesData;
