// import logo from './logo.svg';
import "./App.scss";
import MainRouter from "./components/MainRouter";
import Footer from "./components/Footer";
import useNavToggle from "./hooks/useNavToggle";
import useAppData from "./hooks/useAppData";

function App() {
  const { navToggle, toggleNav } = useNavToggle();
  const { state } = useAppData();

  return (
    <div className={navToggle ? "App toggled-body" : "App"}>
      <MainRouter navControl={{ navToggle, toggleNav }} appData={state} />
      <Footer />
    </div>
  );
}

export default App;
