function IndividualSlide(props) {
  return (
    <div className="each-slide">
      <div
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundSize: props.backgroundSize || "cover",
          backgroundPosition: "center",
          backgroundColor: props.backgroundColor || "#ff8ac8",
          textAlign: props.textAlign || "left",
          fontSize: props.fontSize || "1rem",
        }}
      >
        <span>{props.imageText}</span>
      </div>
    </div>
  );
}

export default IndividualSlide;
