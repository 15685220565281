import { useState } from "react";
import "./MainRouter.scss";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Splash from "./Splash";
import ManagedPage from "../components/ManagedPage";
import Logo from "../images/logo.png";
import Shades from "./minicomponents/Shades";
import MasonryGallery from "./minicomponents/MasonryGallery";
import ServicesRenderer from "./ServicesRenderer";

function MainRouter(props) {
  const { navToggle, toggleNav } = props.navControl;

  const [state, setState] = useState({
    currentPage: "homepage",
  });

  const setCurrentPage = (page) => {
    setState((prev) => {
      return { ...prev, currentPage: page };
    });
  };

  return (
    <Router>
      {navToggle && <Shades toggleNav={toggleNav} />}

      <nav className={navToggle ? "toggled-nav" : ""}>
        <header>
          <img src={Logo} alt="Beauty and Beyond logo" />
          <div className="sub-title">
            Tel: <a href="tel:604-756-1244">{props.appData.phoneNumber}</a>
            <address>{props.appData.address}</address>
          </div>
        </header>
        <main>
          <Link
            onClick={() => {
              setCurrentPage("homepage");
            }}
            to="/"
            className={(state.currentPage === "homepage" && "selected") || ""}
          >
            Homepage
          </Link>
          <Link
            onClick={() => {
              setCurrentPage("gallery");
            }}
            to="/gallery"
            className={(state.currentPage === "gallery" && "selected") || ""}
          >
            Gallery
          </Link>
          <Link
            onClick={() => {
              setCurrentPage("services");
            }}
            to="/services/1"
            className={(state.currentPage === "services" && "selected") || ""}
          >
            Services
          </Link>
          <Link
            onClick={() => {
              setCurrentPage("bookings");
            }}
            to="/bookings"
            className={(state.currentPage === "bookings" && "selected") || ""}
          >
            Bookings
          </Link>
        </main>
        <div id="social-links">
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/beautyandbeyondabbotsford"
            >
              <i className="lab la-facebook"></i>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/beautyandbeyondsalon_abby/"
            >
              <i className="lab la-instagram"></i>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/Beauty+%26+Beyond+Hair+Salon/"
            >
              <i className="lab la-google-plus-g"></i>
            </a>
          </div>
        </div>
      </nav>
      <Switch>
        <Route path="/bookings">
          <ManagedPage toggleNav={toggleNav} pageRef="bookings" />
        </Route>
        <Route path="/gallery">
          <ManagedPage toggleNav={toggleNav} pageRef="gallery">
            <MasonryGallery />
          </ManagedPage>
        </Route>
        <Route path="/services/:providerID?">
          <ManagedPage toggleNav={toggleNav} pageRef="services">
            <ServicesRenderer />
          </ManagedPage>
        </Route>
        <Route path="/">
          <Splash toggleNav={toggleNav} pageRef="homepage" />
        </Route>
      </Switch>
    </Router>
  );
}

export default MainRouter;
